import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Avatar } from 'app/types/avatar/avatar.type';
import { BaseService } from 'app/services/base.service';
import { AppService } from 'app/services/app.service';

interface SpeakingStatePayload {
	ids: string[];
	isSpeaking: boolean;
	whisperData?: any
}

@Injectable({
  providedIn: 'root'
})
export class AvatarService extends BaseService {

	override base_path = 'avatar/';
	onComponentLoaded: BehaviorSubject<string>;
	allowNextComponent: BehaviorSubject<string>;
	onAllComponentsLoaded: BehaviorSubject<boolean>;

	speakingState$ = new Subject<SpeakingStatePayload>();

	constructor(
		override _appService: AppService
	) {
		super(_appService);
		this.onComponentLoaded	= new BehaviorSubject(null);
		this.allowNextComponent = new BehaviorSubject("");
		this.onAllComponentsLoaded = new BehaviorSubject(false);
		this.cacheAvailable = true;
	}

	setSpeakingState(ids: string | string[], isSpeaking: boolean): void {
		const idArray = Array.isArray(ids) ? ids : [ids];
		this.speakingState$.next({ ids: idArray, isSpeaking });
	}

	getRandomVariant( ) {
		return this._appService.post(this.base_path + 'get-random-variant');
	}

	getAvatarFromId(idAvatar: string, idAvatarVariant: string = null): Promise<Avatar> {
		return this.getAll().then(result => {
			let avatar: Avatar = result.items.find((a:Avatar) => a.id === idAvatar);
			if (!avatar) {
				throw new Error('Avatar no encontrado');
			} else {
				if (idAvatarVariant) {
					avatar.variants.forEach( v => {
						v.selected = v.id == idAvatarVariant;
					} )
				}
			}
			return avatar;
		});
	}
}
